/* @import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap'); */

@font-face {
  font-family: 'Filson Pro';
  src: url('/public/fonts/FilsonProThin.otf') format('otf'); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Filson Pro', sans-serif;
  font-style: normal;
  font-weight: 300;
  line-height: 1.6; /* Adjust line height for better readability */
  margin: 0; /* Remove default margin for a cleaner look */
  letter-spacing: 1.1px;
}

html {
  scroll-behavior: smooth;
}

/* ::-webkit-scrollbar {
  width: 10px;
} */

.content::-webkit-scrollbar {
  width: 10px;
  height: 3px;
}


 ::-webkit-scrollbar-track {background: #d0950078;} 


::-webkit-scrollbar-thumb {background: #D09600; border-radius: 20px;}


::-webkit-scrollbar-thumb:hover {background: #d0950078;} 

.content {
  background-color: #F5F5F5;
  /* min-height: 130vh; */
}

.bg {
  background: url("/public/images/pattern.svg");
  background-repeat: no-repeat;
  background-color: #D09600;
  /* background-position: center; */
  background-size: cover;
}

.bg:hover>button {
  display: block;
}

.bg:hover>.hide {
  display: none;
}



.react-calendar {
  padding: 10px;
  border: none !important;
  width: 100% !important;
}

/* Calendar */
.react-calendar__tile--active {
  background: #D09600 !important;
  border-radius: 100%;
  height: 36px !important;
  width: 36px !important;

}

/* Radio buttons */

:root {
  --form-control-color: #D09600;
}

.form-control {
  font-size: 20px;
  line-height: 1.1;
  color: #292D32;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin-bottom: 22px;
}

.check-label {
  align-self: center;
  width: 70%;
  line-height: 1.1;
  color: #292D32;
  display: grid;
  grid-template-columns: 10px auto;
  margin-bottom: 31px;
  text-align: center;
  /* gap: 0.5em; */
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 12px;
  height: 12px;
  border-radius: 1px;
  border: 1px solid #292D32;
  transform: translateY(-0.075em);
  margin-right: 0px;
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 13px;
  height: 13px;
  border-radius: 1px;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);

  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}


input[type="checkbox"]:focus-within {
  color: var(--form-control-color);
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}



input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);

  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}


input[type="radio"]:focus-within {
  color: var(--form-control-color);
}



/* GRAPH */
.apexcharts-canvas>.apexcharts-toolbar {
  background-color: green !important;
  display: none !important;
}

.apexcharts-legend-marker {
  display: none !important;

}

.apexcharts-legend-text {
  display: none !important;
}


.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: red;
  font-weight: bold;
  color: #e0eafc;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 40px;
}
/* .rotate {
  transform: rotate3d(2, -13.57, 12, 15deg);
} */

.account-loading{
  margin-top: -30px;

}

.account-loading:after {
  content: ' .';
  font-size: 40px;
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
      color: gray;
      text-shadow:
          .25em 0 0 rgba(0, 0, 0, 0),
          .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
      color: gray;
      text-shadow:
          .25em 0 0 rgba(0, 0, 0, 0),
          .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
      text-shadow:
          .25em 0 0 gray,
          .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
      text-shadow:
          .25em 0 0 gray,
          .5em 0 0 gray;
  }
}




.loading{
  animation: loading 3s infinite  ease-in-out;
}

@keyframes loading {
 0%{
  transform: scale(0.5) rotate(0);

  opacity: 0.5;
 } 
 50%{
  transform: scale(1.2) rotate(360deg);
  opacity: 1;
 }
 100%{
  transform: scale(0.5) rotate(0);
  opacity: 0.5;
 }
}
@media only screen and (max-width: 620px) {
  input[type="radio"] {
    width: 0.9em;
    height: 0.9em;
    transform: translateY(0.075em);
  }
}


.valid-li{
  list-style: none;
  position: relative;
  padding-left: 0.4em;
}

.valid-li:before {
  content: '✔';
  position: absolute;
  left: -1em;
  top: 0;
  }

  .container-b {
    max-width: 90vw;
    height: 100vh;
    /* background-color: red; */
    /* padding: 70px; */

  }
  
  .box-b {
width: 80%;
height: auto !important;
background-color: #2a1e00;
color: #fff;
    padding: 25px;
    margin: 0 auto;
    border-radius: 10px;
    font-size: 18px;
    letter-spacing: 1px;
    text-align: center;
    display: flex !important;
    justify-content: center;
    flex-direction: row

  }
  
  .title-b {
    font-size: 34px;
    font-weight: bold;
    letter-spacing: 1.1px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-left: 10px;
    text-align: center;

  }

  .slick-prev:before, .slick-next:before {
    font-size: 30px !important;
    color: black !important;
    /* padding: 5px !important; */
  }

  .slick-prev:before, .slick-prev:before {
    font-size: 30px !important;
    color: black !important;

  }
  .slick-prev {
    left: -38px !important;
}

.box-style{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* margin: 20px */
}

.box-b p {
  line-height: inherit;
  width: 400px;
}



@media (max-width: 830px) {
  .img-product {
   display: none !important;
  }
  p {
    width: auto !important
  }
  .box-b {
    height: 390px !important
  }
}

@media (min-width: 800px) {
  .box-style {
    flex-direction: row !important;
    height: 400px;
  }
  .container-b {
    max-width: 1500px;
   
  }
  .box-b {
    
    height: 300px;
  }
}

.title-box-text {
  display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.parent-slider {
  height: 80vh;;
}

.Testimonials-module--Testimonials_main--TbGSH {
  background-color: rgb(42 30 0 / var(--tw-bg-opacity));
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 3rem auto 0;
  max-width: 1920px;
  height: 100%;
}
.Testimonials-module--Testimonials_content--rWHU4 {
  color: #fff;
  margin: auto;
  max-width: 500px;
  padding: 7rem 1rem;
  text-align: center;
  width: 100%;
}

.Testimonials-module--Testimonials_content_title--CSTvX {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.21px;
  line-height: 41px;
  min-height: 150px;
  text-align: center;
}
.Testimonials-module--Testimonials_content_title--CSTvX {
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -1.21px;
  line-height: 41px;
  min-height: 150px;
  text-align: center;
}

.Testimonials-module--Testimonials_image--Vxscn img {
height: 100%;
width: 100%;
  object-fit: cover;
}

.Testimonials-module--Testimonials_content_title--CSTvX {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.1px;
  line-height: 41px;
  min-height: 150px;
  text-align: center;
}

.parent-slider .heading {
  text-transform: uppercase;
}

.Testimonials-module--Testimonials_content_title--CSTvX .heading {
  font-size: 30px;
}

@media screen and (max-width: 576px) {
.Testimonials-module--Testimonials_main--TbGSH {
    grid-template-columns: 1fr;
}
.container-b {
  max-width: 390px;
  padding-left: 15px;
    /* padding-right: 5px; */
    border-radius: 20px;
 
}
}




@media screen and (max-width: 576px){
.Testimonials-module--Testimonials_content--rWHU4 {
    grid-row: 2;
    padding: 3rem 1rem;
}
}

/* Extra small devices (phones) */
@media only screen and (max-width: 600px) {
  /* Your CSS rules for extra small devices go here */
  .Testimonials-module--Testimonials_content--rWHU4 {
    grid-row: 1;
    padding: 3rem 1rem;
}
.Testimonials-module--Testimonials_main--TbGSH {
  grid-template-columns: 1fr;
}
  .container-b {
    /* max-width: 590px; */
    height: 100vh;
   
  }
  .parent-slider {
    height: 90vh;
}


.Testimonials-module--Testimonials_content_title--CSTvX  {
  font-size: 23px;
}
}

/* Small devices (tablets) */
@media only screen and (min-width: 601px) and (max-width: 768px) {
  /* Your CSS rules for small devices go here */
  
  .container-b {
    max-width: 1450px;
   
  }
}

/* Medium devices (desktops, laptops) */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  /* Your CSS rules for medium devices go here */
  .container-b {
    max-width: 1200px;
   
  }
}

/* Large devices (large desktops, TVs) */
@media only screen and (min-width: 1025px) {
  /* Your CSS rules for large devices go here */
  .container-b {
    max-width: 1440px;

   
  }
}


/* Medium devices (desktops, laptops) */
@media only screen and (min-width: 770px) and (max-width: 1020px) {
  /* Your CSS rules for medium devices go here */
  .container-b {
    max-width: 1440px;
   
  }
}

.heading-products {
  font-size: 30px;
    font-weight: 600;
    line-height: 41px;
    line-height: -1.21px;
    margin: auto auto 1rem;
    max-width: 475px;
    text-align: center
}

