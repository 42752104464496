.img-border{
    border-style: solid;
    border-width: 2px;
    border-radius: 0 46px 0 46px;
    padding: 19px;
}


@media only screen and (max-width:620px) {
    .img-border{
        border-width: 0px;
    }
}

.blog-card{
    background: #FFF;
box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.05);
}

.ql-editor{
    min-height: 300px;
}

.blog-desc{
    overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 4;
}